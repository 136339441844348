import { NavBar } from "../../../../components";

//Styles
import "./style.scss";

//icons
import { Fragment, useState } from "react";
import { AiOutlineCalendar } from "react-icons/ai";
import { FiPhoneCall } from "react-icons/fi";
import { IoIosArrowForward } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { useOrder, useUpdateOrderStatus } from "../../../../@core/hooks";
import { useCurrentMerchantSelector } from "../../../../@core/slice";
import { Avatar, Box, Button, Grid, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { CgFileDocument } from "react-icons/cg";
import { HiLocationMarker } from "react-icons/hi";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import {
  LineVectorSVG,
  STATUS_ICON_STROKE,
  StatusVectorSVG,
} from "../../../../assets";
import { useTranslation } from "react-i18next";
import OrderStatusUpdate from "./map";
import { getStatusClassName, getStatusTranslation } from "../../statusHelper";
interface OrderStatusHistory {
  status: string;
  date: string;
  location?: {
    addressDetails: string;
    latitude: number;
    longitude: number;
  };
}

function ViewOrder() {
  const navigation = useNavigate();
  const { orderId } = useParams();
  const { merchant } = useCurrentMerchantSelector();
  const [selectedStatus, setSelectedStatus] = useState<string>(ORDER_STATUS.ACCEPT);
  const getGoogleMapLink = (lat: number,lng: number,zoom: number = 10)=> {
	return `https://www.google.com/maps/place/${lat},${lng}/@${lat},${lng},${zoom}z`;
}

  const [openStatusModal, setOpenStatusModal] = useState(false);
  const { t, i18n } = useTranslation();

  const isRTL = i18n.dir() === "rtl";

  const { data, isLoading, refetch } = useOrder({
    orderId: orderId as string,
    storeId: merchant!!._id,
  });
  const details = data?.data;

  const { mutate: updateOrderStatus } = useUpdateOrderStatus();
  const handleStatusIconClick = (status: string) => {

    setSelectedStatus(status); 
    setOpenStatusModal(true);  
  };
const handleStatusUpdate = (status: any, address?: string, lat?: number, lng?: number) => {

   if (
      (status === ORDER_STATUS.ACCEPT &&
        details?.status !== ORDER_STATUS_MAP.ORDER_PENDING) ||
      (status === ORDER_STATUS.PACKING &&
        details?.status !== ORDER_STATUS_MAP.ORDER_PLACED) ||
      (status === ORDER_STATUS.SHIPPING &&
        details?.status !== ORDER_STATUS_MAP.ORDER_PACKING) ||
      (status === ORDER_STATUS.DELIVERY &&
        details?.status !== ORDER_STATUS_MAP.ORDER_SHIPPING) 
      // (status === ORDER_STATUS.REJECT &&
      //   details?.status !== ORDER_STATUS_MAP.ORDER_PENDING)
    ) {
      toast.error(`${t("auth.CantMove")} ${details?.status} ${t("auth.to")} ${status.charAt(0).toUpperCase() + status.slice(1)}`);
      return;
    }
 

  

    updateOrderStatus(
      {
        storeId: merchant!!._id as string,
        orderId: orderId as string,
        status: status,
        addressDetails: address || '',
        latitude: lat || 0,
        longitude: lng || 0,
      },
      {
        onSuccess() {
          refetch();
          toast.success(t("success.orderUpdated"));
          toast.success(`Status updated to "${status}" with location: ${address}`);

        },
        onError(err:any) {
          toast.error(err);
          toast.error(t("error.orderUpdated"));
        },
      }
    );
  };

  if (isLoading) return <div>Loading...</div>;

  const orderShipping =
    Number(
      details!!.cart.reduce(
        (acc: number, curr) => acc + curr.product.shippingPrice,
        0
      )
    ) || 0;

  const orderTotal = Number(details!!.totalPrice) || 0;

  const orderSubTotal = Number(orderTotal - orderShipping) || 0;

  return (
    <div className="viewOrderContainer">
      <NavBar
        title={t("order.details.title")}
        hasBack={true}
        status={details?.status}
      />
      <div className="viewOrderAction">
        <div className="routes">
          <span onClick={() => navigation("/orders")}>
            {t("menuNavigation.orders")}
          </span>
          <IoIosArrowForward fontWeight={900} size={20} />
          <span style={{ color: "sandybrown" }}>{details?.orderRefId}</span>
        </div>
        <div className="rightSide">
          <AiOutlineCalendar size={20} />
          {`${new Date(details!!.createdAt)
            .toUTCString()
            .split(" ")
            .slice(0, -1)
            .join(" ")}`}
        </div>
      </div>

      <div className="content">
        <div className="maincontent">
          <div className="header">{t("order.details.productDetails")}</div>
          <Grid
            container
            spacing={3}
            alignItems="center"
            bgcolor="white"
            marginLeft="0.03rem"
            marginTop="0.04rem"
            maxWidth="100%"
            paddingBottom="0.8rem"
          >
            {details?.cart?.map((item, key) => {
              return (
                <Box
                  sx={{
                    margin: "1rem 0 0.5rem 1.2rem",

                    ...(!isRTL && {
                      margin: "1rem 1.2rem 0.5rem 0",
                    }),
                  }}
                  display="flex"
                  key={key}
                >
                  <Box mr={2} ml={2}>
                    <img src={item?.variant?.images?.[0]||item?.product?.mainImage} alt="" />
                  </Box>

                  <Grid item xs={12} md={6}>
                    <Box display="flex" alignItems="center">
                      <Box lineHeight={1}>
                        <Typography
                          variant="h6"
                          color="#1A73E7"
                          fontWeight="700"
                          sx={{ cursor: "pointer" }}
                          
                        >
                          <div onClick={()=>{navigation(`/products/${item?.product._id}`)}}>
                          {item?.product?.name}

                          </div>
                        </Typography>
                        <Grid container rowSpacing={2} columnSpacing={8}>
                          <Grid item md={6}>
                            <Typography fontWeight="200">
                              {t("order.details.color")}
                            </Typography>
                          </Grid>
                          <Grid item md={6}>
                            <Typography>
                              {item?.variant?.color.name ?? ""}
                            </Typography>
                          </Grid>
                          <Grid item md={6}>
                            <Typography fontWeight="200">
                              {t("order.details.size")}
                            </Typography>
                          </Grid>
                          <Grid item md={6}>
                            <Typography>
                              {item?.variant?.size.size ?? ""}
                            </Typography>
                          </Grid>
                          <Grid item md={4}>
                            <Typography fontWeight="200">
                              {t("order.details.sku")}
                            </Typography>
                          </Grid>
                          <Grid item md={8}>
                            <Typography>{item?.product.sku ?? ""}</Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Box lineHeight={1}>
                      <Box display="flex" alignItems="center">
                        <Grid container rowSpacing={2} columnSpacing={11}>
                          <Grid item md={12}>
                            <Typography fontWeight="200">{t("")}</Typography>
                          </Grid>
                          <Grid item md={2}>
                            <Typography fontWeight="200">
                              {t("order.details.discount")}
                            </Typography>
                          </Grid>
                          <Grid item md={9}>
                            <Typography>
                              {item?.discount ?? t("order.details.noData")}
                            </Typography>
                          </Grid>
                          <Grid item md={6}>
                            <Typography fontWeight="200">
                              {t("order.details.quantity")}
                            </Typography>
                          </Grid>
                          <Grid item md={6}>
                            <Typography>
                              {isRTL
                                ? Number(item?.quantity).toLocaleString(
                                    "ar-EG",
                                    { useGrouping: false }
                                  )
                                : item?.quantity ?? 1}
                            </Typography>
                          </Grid>
                          <Grid item md={6}>
                            <Typography fontWeight="200">
                              {t("order.details.price")}
                            </Typography>
                          </Grid>
                          <Grid item md={6}>
                            <Typography>
                              {isRTL
                                ? Number(item?.product.price).toLocaleString(
                                    "ar-EG",
                                    { useGrouping: false }
                                  )
                                : item?.product.price}{" "}
                              {t("currency.kwd")}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                </Box>
              );
            })}
          </Grid>

          <div className="paymentContainer">
            <div className="header">{t("order.details.paymentSummary")}</div>
            <div className="container">
              <div>
                <div className="title">{t("order.details.subTotal")}</div>
                <div className="data">
                  {isRTL
                    ? orderSubTotal.toLocaleString("ar-EG", {
                        useGrouping: false,
                      })
                    : orderSubTotal}{" "}
                  {t("currency.kwd")}
                </div>
              </div>
              <div>
                <div className="title">{t("order.details.shipping")}</div>
                <div className="data">
                  {isRTL
                    ? orderShipping.toLocaleString("ar-EG", {
                        useGrouping: false,
                      })
                    : orderShipping}{" "}
                  {t("currency.kwd")}
                </div>
              </div>
            </div>
          </div>

          <div className="customerPaymentContainer">
            <div className="header">
              <div className="title">{t("order.details.payedByCustomer")}</div>
              <div className="data">
                {isRTL
                  ? orderTotal.toLocaleString("ar-EG", {
                      useGrouping: false,
                    })
                  : orderTotal}{" "}
                {t("currency.kwd")}
              </div>
            </div>
            <div className="container">
              <div>
                <div className="title">{t("order.details.paymentMethod")}</div>
                <div className="data">{details?.paymentType}</div>
              </div>
            </div>
          </div>
        </div>
        
        <Box>
          <Grid container maxWidth="100%" rowSpacing={2}>
            <Grid item className="customerDetailsHeader" md={12} xs={12}>
              <CgFileDocument size={32} />
              <Typography variant="h6">
                {t("order.details.customerDetails")}
              </Typography>
            </Grid>

            <hr />

            <Grid item marginLeft="1.4rem" md={11} xs={12} marginBottom="1rem">
              <Box
                bgcolor="#F8F9FA"
                padding="1.5rem"
                display="flex"
                alignItems="center"
                borderRadius={4}
              >
                <Grid item md={4} xs={12}>
                  <Box mr={3}>
                    <Avatar
                      src={details?.buyer.image}
                      sx={{ height: "64px", width: "64px" }}
                    />
                  </Box>
                </Grid>
                <Grid item md={8} xs={12}>
                  <Typography fontWeight="200">
                    {details?.buyer?.name}
                  </Typography>
                </Grid>
              </Box>
            </Grid>

            <br />

            <Grid item marginLeft="2rem" md={12} xs={12}>
              <Grid container rowGap={1} spacing={2}>
                <Grid item md={12} xs={12}>
                  <Typography variant="h6">
                    {t("order.details.contactInfo")}
                  </Typography>
                </Grid>
                <Grid item md={2} xs={12}>
                  <MdEmail
                    style={{
                      verticalAlign: "middel",
                    }}
                    size={24}
                  />
                </Grid>
                <Grid item md={10} xs={12}>
                  <Typography fontWeight="400">
                    {details?.buyer?.email}
                  </Typography>
                </Grid>
                <Grid item md={2} xs={12}>
                  <FiPhoneCall
                    style={{
                      verticalAlign: "middel",
                    }}
                    size={24}
                  />
                </Grid>
                <Grid sx={{display:"flex",gap:0.5}} item md={10} xs={12}>
                  <Typography fontWeight="400">
                    {details?.buyer?.countryCode} 
                  </Typography>
                    <Typography fontWeight="400">
                     {details?.buyer?.phoneNumber}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item marginLeft="2rem" md={12} xs={12} marginBottom="1rem">
              <Grid container rowGap={1}>
                <Grid item md={12} xs={12}>
                  <Typography variant="h6">
                    {t("order.details.shippingInfo")}
                  </Typography>
                </Grid>

                <Grid item md={2} xs={12}>
                  <HiLocationMarker
                    style={{
                      verticalAlign: "middel",
                    }}
                    size={24}
                  />
                </Grid>
                <Grid item md={10} xs={12}>
                  <Typography fontWeight="400">
                    <span>
                      {details?.address?.addressDetails} 
                      <span style={{padding:"0 3px"}}/>
                      {details?.address?.addressName}

                      <span style={{ margin:"0 15px"}}>
                        {details?.address?.city?.name},
                      
                      {details?.address?.country}
                      </span>
                    </span>
                    
                  </Typography>
                  <Typography fontWeight="400">
                    <span>
                      <span style={{padding:"0 10px",fontWeight:"bold"}}>
                      {t("More info")} 
                    </span>
                      {details?.address?.addressDetails} 

                    </span>
                    
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

      </div>
      <div className="stConteent">
        
          <Box  className="statusContainer">
            <div className="header">{t("order.details.orderStatus")}</div>
            <Grid
              container
              sx={{
               maxWidth:{sm:"80%",md:"60%"},
                marginLeft: "6rem",
                marginTop: "3rem",
                marginBottom: "1rem",

                ...(isRTL && {
                  direction: "rtl",
                  marginRight: "6rem",
                }),
              }}
            >
              {Array.from({ length: 4 }).map((_, index) => (
                <Grid item md={3}>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center">
                      <StatusVectorSVG
                        stroke={
                          index === 0
                            ? details?.status ===
                                ORDER_STATUS_MAP.ORDER_PLACED ||
                              details?.status ===
                                ORDER_STATUS_MAP.ORDER_PACKING ||
                              details?.status ===
                                ORDER_STATUS_MAP.ORDER_SHIPPING ||
                              details?.status ===
                                ORDER_STATUS_MAP.ORDER_DELIVERED
                              ? STATUS_ICON_STROKE.COLORED
                              : STATUS_ICON_STROKE.GRAY
                            : index === 1
                            ? details?.status ===
                                ORDER_STATUS_MAP.ORDER_PACKING ||
                              details?.status ===
                                ORDER_STATUS_MAP.ORDER_SHIPPING ||
                              details?.status ===
                                ORDER_STATUS_MAP.ORDER_DELIVERED
                              ? STATUS_ICON_STROKE.COLORED
                              : STATUS_ICON_STROKE.GRAY
                            : index === 2
                            ? details?.status ===
                                ORDER_STATUS_MAP.ORDER_SHIPPING ||
                              details?.status ===
                                ORDER_STATUS_MAP.ORDER_DELIVERED
                              ? STATUS_ICON_STROKE.COLORED
                              : STATUS_ICON_STROKE.GRAY
                            : details?.status ===
                              ORDER_STATUS_MAP.ORDER_DELIVERED
                            ? STATUS_ICON_STROKE.COLORED
                            : STATUS_ICON_STROKE.GRAY
                        }
                        onClick={() => handleStatusIconClick(
                          index === 0 ? ORDER_STATUS.ACCEPT
                                : index === 1
                                ? ORDER_STATUS.PACKING
                                : index === 2
                                ? ORDER_STATUS.SHIPPING
                                : ORDER_STATUS.DELIVERY)}

                      />
                      {index === 3 ? (
                        <></>
                      ) : (
                        <LineVectorSVG
                          stroke={
                            index === 0
                              ? details?.status ===
                                  ORDER_STATUS_MAP.ORDER_PLACED ||
                                details?.status ===
                                  ORDER_STATUS_MAP.ORDER_PACKING ||
                                details?.status ===
                                  ORDER_STATUS_MAP.ORDER_SHIPPING ||
                                details?.status ===
                                  ORDER_STATUS_MAP.ORDER_DELIVERED
                                ? STATUS_ICON_STROKE.COLORED_LINE
                                : STATUS_ICON_STROKE.GRAY
                              : index === 1
                              ? details?.status ===
                                  ORDER_STATUS_MAP.ORDER_PACKING ||
                                details?.status ===
                                  ORDER_STATUS_MAP.ORDER_SHIPPING ||
                                details?.status ===
                                  ORDER_STATUS_MAP.ORDER_DELIVERED
                                ? STATUS_ICON_STROKE.COLORED_LINE
                                : STATUS_ICON_STROKE.GRAY
                              : index === 2
                              ? details?.status ===
                                  ORDER_STATUS_MAP.ORDER_SHIPPING ||
                                details?.status ===
                                  ORDER_STATUS_MAP.ORDER_DELIVERED
                                ? STATUS_ICON_STROKE.COLORED_LINE
                                : STATUS_ICON_STROKE.GRAY
                              : STATUS_ICON_STROKE.GRAY
                          }
                        />
                      )}
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              justifyContent: "space-between",
                 maxWidth:{sm:"90%",md:"55%"},
                marginLeft: 2,
                marginRight: 8,
                padding: 1,
                ...(!isRTL && {
                  marginLeft: 8,
                  marginRight: 2,
                }),
              }}
            >
              {Array.from({ length: 4 }).map((_, index) => (
                <Typography key={index} fontWeight={400} variant="subtitle1">
                  {index === 0
                    ? t("order.details.orderPlaced")
                    : index === 1
                    ? t("order.details.orderPacked")
                    : index === 2
                    ? t("order.details.orderShipped")
                    : t("order.details.orderDelivered")}
                </Typography>
              ))}
    
            </Box>

                <Box sx={{ maxWidth:{sm:"90%",md:"60%"}}}>
               {details?.status==="Pending"&&(
              <Box display={"flex"} justifyContent={"flex-end"} gap={3} className="reject-button">
              <Button
                variant="contained"
                color="error"
                onClick={() => handleStatusUpdate(ORDER_STATUS.REJECT)}
              >
                {t("order.RejectOrder")}
              </Button>
            </Box>
            )}
            {details?.status!=="Pending"&& details?.status!=="Cancelled"&&details?.status !=="Delivered"&&(
              <Box display={"flex"} justifyContent={"flex-end"} gap={3} className="reject-button">
              <Button
                variant="contained"
                color="error"
                onClick={() => handleStatusUpdate(ORDER_STATUS.REJECT)}
              >
                {t("Cancel Order")}
              </Button>
            </Box>
            )}
            </Box>
          </Box>
          
          <Box pb={3} padding={2}>
             <Typography mb={3} variant="h6" gutterBottom>
                   {t("Order Status History")}
             </Typography>
           <TableContainer component={Paper}>
      <Table>

      <TableHead>
        <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
          <TableCell sx={{ fontWeight: "bold" }}>{t("Status")}</TableCell>
          <TableCell sx={{ fontWeight: "bold" }}>{t("Date")}</TableCell>
          <TableCell sx={{ fontWeight: "bold", width: "50%" }}>{t("Address")}</TableCell>
          <TableCell sx={{ fontWeight: "bold" }}>{t("Lat, Long")}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {details?.orderStatusHistory?.map((entry, index) => (
          <TableRow key={index}
            sx={{
              "&:hover": { backgroundColor: "#f1f1f1" },
            }}>
            <TableCell>
              <span className={getStatusClassName(entry?.status, isRTL)}>
              {entry?.status}
            </span>
              </TableCell>
            <TableCell>{entry?.date}</TableCell>
            <TableCell>
              {entry?.location?.addressDetails || "-"}
            </TableCell>
            <TableCell>
              {entry?.location
                ? `${entry.location.latitude},${entry.location.longitude}`
                : "-"}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
           </TableContainer>
         </Box>
      </div>

        {openStatusModal && details?.status !=="Delivered"&& details?.status !=="Cancelled"&& (
        <OrderStatusUpdate
          open={openStatusModal}
          close={()=>setOpenStatusModal(false)}
          initialStatus={selectedStatus}
          onUpdateStatus={handleStatusUpdate}
          order={details}
        />
      )}
    </div>

  );
}

export default ViewOrder;

const ORDER_STATUS = {
  ACCEPT: "accept",
  PACKING: "packing",
  SHIPPING: "shipping",
  DELIVERY: "delivery",
  REJECT: "reject",
};

const ORDER_STATUS_MAP = {
  ORDER_PENDING: "Pending",
  ORDER_PLACED: "Placed",
  ORDER_PACKING: "Packing",
  ORDER_SHIPPING: "Shipping",
  ORDER_DELIVERED: "Delivered",
  ORDER_REJECTED: "orderRejected",
};
