
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { Marker, useMapEvents, MapContainer, TileLayer } from 'react-leaflet';
import { Map } from 'leaflet';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { Button, TextField, Modal, Select, MenuItem, Box, CircularProgress, InputLabel, FormHelperText, Grid } from '@mui/material';
import { toast } from "react-toastify";
import debounce from 'lodash.debounce';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { useTranslation } from 'react-i18next';

const ORDER_STATUS = {
  ACCEPT: "accept",
  PACKING: "packing",
  SHIPPING: "shipping",
  DELIVERY: "delivery",
  REJECT: "reject",
};
const ORDER_STATUS_MAP = {
  ORDER_PENDING: "Pending",
  ORDER_PLACED: "Placed",
  ORDER_PACKING: "Packing",
  ORDER_SHIPPING: "Shipping",
  ORDER_DELIVERED: "Delivered",
  ORDER_REJECTED: "orderRejected",
};

interface OrderStatusUpdateProps {
  initialStatus: string;
  onUpdateStatus: (status: string, address?: string, lat?: number, lng?: number) => void;
  open: boolean;
  close: () => void;
  order:any
}

const OrderStatusUpdate: React.FC<OrderStatusUpdateProps> = ({ initialStatus, onUpdateStatus, open, close ,order}) => {
  const [status, setStatus] = useState<string>(initialStatus);
  const [address, setAddress] = useState<string>('');
  const [coordinates, setCoordinates] = useState<{ lat: number; lng: number } | null>(null);
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const provider = new OpenStreetMapProvider();
  const mapRef = useRef<Map>(null);
  const [isDeliver, setIsDeliver] = useState(initialStatus==="delivery"?true:false||status==="delivery"?true:false);
  console.log(initialStatus,"initialStatus");
  console.log(order?.address,"order?.address");



 const {t}=useTranslation()
  const customIcon = new L.Icon({
    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
  });

const handleAddressSearch = async (value: string) => {
  setLoading(true);
  try {
    const results = await provider.search({ query: value });
    setSuggestions(results);
  } catch (error: any) {
    console.error("Error fetching address:", error);
    toast.error("Address search failed. Please try again.");
  } finally {
    setLoading(false);
  }
};


  const debouncedSearch = useCallback(debounce(handleAddressSearch, 300), []);

useEffect(() => {
  navigator.geolocation.getCurrentPosition(async (position) => {
    const { latitude, longitude } = position.coords;

    setCoordinates({ lat: latitude, lng: longitude });
        if (mapRef.current) {
      mapRef.current.setView([latitude, longitude], 15);
    }
    try {
      const results = await provider.search({ query: `${latitude}, ${longitude}` });
      const fullAddress = results[0]?.label || "Unknown Address";
      setAddress(fullAddress); 
    } catch (error) {
      console.error("Failed to fetch address:", error);
      toast.error("Failed to fetch the address. Please try again.");
    }
  });
}, []);

  const handleStatusChange = (newStatus: string) => {
    setStatus(newStatus);
  };

  const handleMapSelect = async () => {
      if (!coordinates) {
      return;
    }

      const { lat, lng } = coordinates;
      try {
        const results = await provider.search({ query: `${lat}, ${lng}` });
        const fullAddress = results[0]?.label || 'Unknown Address';
        setAddress(fullAddress);
        onUpdateStatus(status, fullAddress, lat, lng);
        close();
      } catch (error) {
        toast.error("Failed to retrieve address. Please try again.");
      }
  };
  const handleDeliverSelect = async () => {
    const fullAddress= [
     order?.address?.addressName,
     order?.address?.addressDetails,
     order?.address?.addressDetails2,
     order?.address?.city?.name,
     order?.address?.country
    ].filter(Boolean).join(', ');

    const  lat= order?.address?.location?.coordinates?.[0];
    const  lng= order?.address?.location?.coordinates?.[1];

    try {
      onUpdateStatus(status, fullAddress, lat, lng);
      close();
    } catch (error) {
      toast.error("Failed to retrieve address. Please try again.");
    }
};
  const handleSuggestionClick = (suggestion: any) => {
    const { x, y, label } = suggestion;
    setCoordinates({ lat: y, lng: x });
    setAddress(label);
    setSuggestions([]);
    if (mapRef.current) {
      mapRef.current.setView([y, x], 15);
    }
  };

  const LocationMarker = () => {
    const fetchAddress = async (lat: number, lng: number) => {
  try {
    const results = await provider.search({ query: `${lat}, ${lng}` });
    const address = results[0]?.label || `Latitude: ${lat}, Longitude: ${lng}`;
    setAddress(address);
  } catch (error) {
    console.error("Error fetching address:", error);
    toast.error("Failed to retrieve address. Please try again.");
  }
    };

    useMapEvents({
      click(e) {
        const { lat, lng } = e.latlng;
        setCoordinates({ lat, lng });
        fetchAddress(lat, lng); 

      },
    });

    return coordinates ? <Marker position={coordinates} icon={customIcon} /> : null;
  };

  return (
    <Modal open={open} onClose={close}>

      <Box sx={{
          backgroundColor: 'white',
           maxWidth: '1100px',
           minWidth:"1000px",
            margin: 'auto',  
           position: 'absolute',
           top: '50%',
           left: '50%',
           transform: 'translate(-50%, -50%)',
          boxShadow: 24,
          py: 5,
          px:3
      }}>
       {isDeliver === true?<>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <h2>{t("Update Status")}</h2>
          <Box mt={2}>
          <InputLabel
            sx={{ textAlign: "initial !important" }}
            className="label" >
            {t("Select status")}
          </InputLabel>
          <div className='form-control'>
            {status}
          </div>
        </Box>
        <Box my={2} display="flex" flexDirection="column">
            <InputLabel
            sx={{ textAlign: "initial !important" }}
            className="label" >
            {t("Address")}
          </InputLabel>
          <div className='form-control'>
            { order?.address?.addressName  } 
            { order?.address?.addressDetails}
            {order?.address?.addressDetails2} {" "}{order?.address?.city?.name}, {order?.address?.country}
          </div>
        </Box>

        <Box  width={"100%"} display="flex" gap={2} mb={2}>
          <Box width={"100%"}>
            <InputLabel
            sx={{ textAlign: "initial !important" }}
            className="label" >
            {t("Latitude")}
          </InputLabel>
          <div className='form-control'>
            {order?.address?.location?.coordinates?.[0]}
          </div>
          </Box>
          <Box width={"100%"}>
            <InputLabel
            sx={{ textAlign: "initial !important" }}
            className="label" >
            {t("Longitude")}
          </InputLabel>
          <div className='form-control'>
            {order?.address?.location?.coordinates?.[1]}
          </div>
          </Box>

        </Box>
       <Box display={"flex"} justifyContent={"end"} gap={2}>

        <Button
          variant="outlined"
          color="info"
          disabled={!status}

          onClick={()=>setIsDeliver(false)} sx={{ mt: 2,textTransform:"capitalize"}}>
         {("Change address")}
        </Button>
        <Button
          variant="contained"
          color="info"
          disabled={!status}

          onClick={handleDeliverSelect} sx={{ mt: 2,textTransform:"capitalize"}}>
         {("Update status")}
        </Button>
       </Box>

          </Grid>

        </Grid>
       </>
       :<>
       <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <h2>{t("Update Status")}</h2>
          <Box mt={2}>
          <InputLabel
            sx={{ textAlign: "initial !important" }}
            className="label" >
            {t("Select status")}
          </InputLabel>
          <Select
            value={status}
            displayEmpty
            onChange={(event) => handleStatusChange(event.target.value)}
            fullWidth
            sx={{
               borderRadius: "12px",
            }}
            required
        >
          {Object.values(ORDER_STATUS).map((stat) => (
            <MenuItem key={stat} value={stat}>
              {stat.charAt(0).toUpperCase() + stat.slice(1)}
            </MenuItem>
          ))}
          </Select>
        </Box>
        <Box my={2} display="flex" flexDirection="column">
            <InputLabel
            sx={{ textAlign: "initial !important" }}
            className="label" >
            {t("Address")}
          </InputLabel>
            <TextField
             placeholder="Type address"
            className="textField"
            value={address}
           onChange={(e) => {
             const value = e.target.value;
              setAddress(value);
             if (value.trim() === ""||value===null) {
                setCoordinates(null); 
                setSuggestions([]);   
             } else {
               debouncedSearch(value); 
             }
           }}
           fullWidth
            />

          {loading && <CircularProgress size={24} sx={{ ml: 1 }} />}
          <Box>
            {suggestions.map((suggestion) => (
              <Box
                key={suggestion.x}
                sx={{ cursor: 'pointer', padding: '8px', backgroundColor: '#f0f0f0', marginTop: '4px' }}
                onClick={() => handleSuggestionClick(suggestion)}
              >
                {suggestion.label}
              </Box>
            ))}
          </Box>
        </Box>

        <Box  width={"100%"} display="flex" gap={2} mb={2}>
          <Box width={"100%"}>
            <InputLabel
            sx={{ textAlign: "initial !important" }}
            className="label" >
            {t("Latitude")}
          </InputLabel>
          <TextField
            className="textField"
            value={coordinates?.lat || ''}
            onChange={(e:any) => setCoordinates((coords:any) => ({ ...coords, lat: parseFloat(e.target.value) || 0 }))}
             fullWidth
             InputProps={{
              readOnly: true, 
            }}
          />
          </Box>
          <Box width={"100%"}>
            <InputLabel
            sx={{ textAlign: "initial !important" }}
            className="label" >
            {t("Longitude")}
          </InputLabel>
          <TextField
            className="textField"
            value={coordinates?.lng || ''}
            onChange={(e:any) => setCoordinates((coords:any) => ({ ...coords, lng: parseFloat(e.target.value) || 0 }))}
            fullWidth
            InputProps={{
              readOnly: true, 
            }}
          />
          </Box>

        </Box>
        <Box display={"flex"} justifyContent={"end"} gap={2}>
        <Button
          variant="outlined"
          color="info"
          disabled={!status}

          onClick={()=>setIsDeliver(true)} sx={{ mt: 2,textTransform:"capitalize"}}>
         {("Back")}
        </Button>
        <Button
          variant="contained"
          color="info"
          disabled={!status}

          onClick={handleMapSelect} sx={{ mt: 2,textTransform:"capitalize"}}>
         {("Update status")}
        </Button>
        </Box>


          </Grid>
          <Grid item xs={12} sm={12} md={6}>
          <MapContainer center={[coordinates?.lat || 0, coordinates?.lng || 0]} zoom={15} ref={mapRef} style={{ height: '400px', width: '100%' }}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <LocationMarker />
        </MapContainer>
          </Grid>

        </Grid>
       </>}
       
      </Box>
    </Modal>
  );
};

export default OrderStatusUpdate;
